// Imports
import Alpine from 'alpinejs';

class Search {
    constructor() {
        this.init();
        this._hideOnMobile();
    }
    init() {
        this.desktopSearch = document.querySelector('.search-desktop');
        this.firstSlug = location.pathname.split('/')[1];

        // Set up Alpine store to control nav toggle
        Alpine.store('search', {
            open: this.firstSlug === 'search' && window.innerWidth >= 1024  ? true : false,
            toggle(focus) {
                if (this.open) {
                    // Close nav
                    this.open = false;
                    console.log('Closing search');
                }
                else {
                    // Open nav
                    this.open = true;
                    console.log('Opening search');

                    // Focus input
                    if (focus) {
                        setTimeout(() => {
                            document.querySelector('.search-desktop-input').focus();
                        },100);

                    }
                }
            }
        });

        // Escape key press
        window.addEventListener('keydown', (e) => {
            if (e.key === 'Escape' && Alpine.store('search').open) {
                Alpine.store('search').toggle();
            }
        });

        // Window resize
        window.addEventListener('resize', (e) => {
            if (window.innerWidth < 1024 && Alpine.store('search').open) {
                Alpine.store('search').toggle();
            }

            this._hideOnMobile();
        });
    }
    _hideOnMobile() {
        if (window.innerWidth < 1024) {
            this.desktopSearch.classList.add('!hidden');
        }
        else {
            this.desktopSearch.classList.remove('!hidden');
        }
    }
}

export default new Search();



