// Imports
import gsap from 'gsap';

class Contact {
	constructor(container) {
        const footerNewsletterEl = document.getElementById('footer-newsletter');

        if (footerNewsletterEl) {
            // Hide footer newsletter
            gsap.to(footerNewsletterEl, {
                duration: .5,
                alpha: 0
            });
        }
	}
    leave() {
        const footerNewsletterEl = document.getElementById('footer-newsletter');

        if (footerNewsletterEl) {
            // Show footer newsletter
            gsap.to(footerNewsletterEl, {
                duration: .5,
                alpha: 1
            });
        }
    }
}

export default Contact;
