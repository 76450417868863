// Imports
import gsap from 'gsap';

class Home {
	constructor(container) {
        // Function to handle the parallax effect
        function parallaxScroll() {
            // Calculate the scroll position and the element's position relative to the viewport
            const scrollY = window.pageYOffset;
            const element = document.querySelector('.header-img-parallax');
            const elementTop = element.getBoundingClientRect().top;
            const elementHeight = element.offsetHeight;

            // Calculate the parallax effect by adjusting the element's position
            const parallax = scrollY - elementTop;

            // Apply the parallax effect using GSAP
            gsap.to(element, { y: parallax * 0.11, ease: 'power2.out' });
        }

        // Add an event listener to call the parallaxScroll function on scroll
        window.addEventListener('scroll', parallaxScroll);
	}
}

export default Home;
