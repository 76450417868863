// Imports
import LazyLoad from 'vanilla-lazyload';
import reframe from 'reframe.js';
import Splide from '@splidejs/splide';

// Project imports
import Counter from '@js/modules/Counter';
import Navigation from '@js/modules/Navigation';
import Filters from '@js/modules/Filters';
import Forms from '@js/modules/Forms';
import ProjectBar from '@js/modules/ProjectBar';
import ProjectFilters from '@js/modules/ProjectFilters';
import ProjectGrid from '@js/modules/ProjectGrid';
import ProjectNav from '@js/modules/ProjectNav';
import ProjectPanel from '@js/modules/ProjectPanel';
import Search from '@js/modules/Search';

class Page {
    constructor() {

    }
    init() {
        // External links
        window.MAPS.modules.ExternalLinks.build();

        /**
         * Open external links in new tab
         */
        const extLinks = document.querySelectorAll('[data-external]');
        if (extLinks) {
            extLinks.forEach((item, i) => {
                item.addEventListener('click', (e) => {
                    let url = item.getAttribute('href');
                    window.open(url, '_blank').focus();
                });
            });
        }

        // Test for Formie
        if (window.Formie) {
            // Init forms so that all features are set up for the page
            window.Formie.initForms();
        }

        /**
         * Lazy load
         */
        let lazyLoadInstance = new LazyLoad({
            threshold: 400
        });

        /**
         * Reframe
         */
        reframe('.reframe');

        /**
         * Counters
         */
        Counter.init();

        /**
         * Filters
         */
        Filters.init();
        ProjectFilters.init();

        /**
         * Forms
         */
        Forms.start();

        /**
         * Projects
         */
        ProjectNav.init();
        ProjectPanel.init();
        ProjectGrid.init();
        ProjectBar.init();


        /**
         * Sliders
         */
        const carouselEls = document.querySelectorAll('.carousel');
        carouselEls.forEach((item, i) => {
            new Splide(item, {
                arrows: true,
                arrowPath: 'm35.7 15.3-1.1.9 2.7 3H0v1.5h37.4l-2.7 3 1.1.9L40 20l-4.3-4.7z',
                drag: true,
                gap: 0,
                padding: '15%',
                pagination: true,
                perMove: 1,
                perPage: 1,
                type: 'loop',
                breakpoints: {
                    768: {
                        arrowPath: 'm30.1 9.2-2.4 2.2 6.2 6.9H.2v3.4h33.7l-6.2 6.9 2.4 2.2L40 20 30.1 9.2z',
                        padding: '0'
                    },
                    1200: {
                        padding: '20%',
                    }
                }
            }).mount()
        });
    }
}

export default new Page();
