// Imports
import barba from '@barba/core';

class Filters {
    constructor() {
    }
    init() {
        this.attribute = 'data-filter';

        const filterEls = document.querySelectorAll(`[${this.attribute}]`);

        filterEls.forEach((filter) => {
            // Listen for the change event on the select element
            filter.addEventListener('change', function() {
                // Get the selected option's value (which is the URL of the chosen page)
                const selectedPage = this.value;
                console.log({selectedPage});

                // Use Barba.js to navigate to the selected page
                barba.go(selectedPage);
            });
        });
    }
}

export default new Filters();
