// Imports
import barba from '@barba/core';

class ProjectFilters {
    constructor() {
    }
    init() {
        const filterSelects = document.querySelectorAll('[data-project-filter]');

        filterSelects.forEach(select => {
            select.addEventListener('change', updateURL);
        });

        function updateURL() {
            const baseUrl = window.location.href.split('?')[0]; // Remove existing params
            let newUrl = baseUrl;

            filterSelects.forEach(select => {
                const selectedValue = select.value;
                const paramName = select.getAttribute('data-param');

                if (selectedValue) {
                    if (newUrl === baseUrl) {
                        newUrl += `?${paramName}=${selectedValue}`;
                    } else {
                        newUrl += `&${paramName}=${selectedValue}`;
                    }
                }
            });

            // Use Barba.js to navigate to the selected page
            barba.go(newUrl);
        }
    }
}

export default new ProjectFilters();
