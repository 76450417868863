// Imports
import Alpine from 'alpinejs';

// Project imports
import PageScrolling from '@js/modules/PageScrolling';

class Navigation {
    constructor() {
        this.nav = document.querySelector('.mobile-nav');

        // Set up Alpine store to control nav toggle
        Alpine.store('nav', {
            open: false,
            toggle() {
                if (this.open) {
                    // Close nav

                    this.open = false;
                    console.log('Closing nav');

                    // Enable scroll
                    PageScrolling.unlock(document.querySelector('.mobile-nav-scroll'));
                }
                else {
                    // Open nav

                    this.open = true;
                    console.log('Opening nav');

                    // Disable scroll
                    PageScrolling.lock(document.querySelector('.mobile-nav-scroll'));
                }
            }
        });

        // Escape key press
        window.addEventListener('keydown', (e) => {
            if (e.key === 'Escape' && Alpine.store('nav').open) {
                Alpine.store('nav').toggle();
            }
        });
    }
}

export default new Navigation();



