import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

class ProjectNav {
    constructor() {
    }
    init() {
        this.sectionScroll();
        this.btnScroll();
    }
    sectionScroll() {
        const section1 = document.getElementById('projects-case-studies');
        const section2 = document.getElementById('projects-overviews');

        // Check for sections
        if (section1 && section2) {
            const button1 = document.getElementById('btn-projects-case-studies');
            const button2 = document.getElementById('btn-projects-overviews');
            const headerHeight = document.querySelector('.mn-hdr').offsetHeight;
            const projectNavHeight = document.querySelector('.projects-nav').offsetHeight;

            // Add active class to first button
            button1.classList.add('_active');

            function updateActiveButton() {
                const section2Bounds = section2.getBoundingClientRect();

                // Calculate the threshold based on the header height
                const threshold = section2Bounds.top - (headerHeight + projectNavHeight);

                if (threshold <= 0) {
                    button1.classList.remove('_active');
                    button2.classList.add('_active');
                } else {
                    button1.classList.add('_active');
                    button2.classList.remove('_active');
                }
            }

            window.addEventListener('scroll', updateActiveButton);
            window.addEventListener('resize', updateActiveButton);
        }
    }
    btnScroll() {
        const projectsBtns = document.querySelectorAll('[data-project-btn]');

        // Grab the prefers reduced media query.
        const prefersReducedMediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");

        projectsBtns.forEach(btn => {
            const scrollEl = btn.getAttribute('data-project-btn');

            btn.addEventListener('click', function (e) {
                e.preventDefault();

                let targetElement = document.querySelector(scrollEl);

                // If no element jump out
                if (!targetElement) {
                    console.error('No element found on the page');
                    return;
                }

                let y = targetElement.getBoundingClientRect().top + (window.pageYOffset || document.body.scrollTop);

                const header = document.querySelector('.mn-hdr').offsetHeight;
                const projectNav = document.querySelector('.projects-nav').offsetHeight;

                y = y - (header + projectNav) + 1;

                gsap.to(window, {
                    duration: !prefersReducedMediaQuery || prefersReducedMediaQuery.matches ? 0 : .4,
                    scrollTo: y
                });
            });
        });
    }
}

export default new ProjectNav();
