class ProjectBar {
    constructor() {

    }
    init() {
        const projectItemEls = document.querySelectorAll('.project-item');

        projectItemEls.forEach(project => {
            const projectBarHoverEls = project.querySelectorAll('.project-bar-hover-el');

            projectBarHoverEls.forEach(el => {
                el.addEventListener('mouseenter', function() {
                    const projectBar = project.querySelector('.project-bar');

                    if (projectBar) {
                        projectBar.classList.add('_hover');
                    }
                });

                el.addEventListener('mouseleave', function() {
                    const projectBar = project.querySelector('.project-bar');

                    if (projectBar) {
                        projectBar.classList.remove('_hover');
                    }
                });
            });
        });
    }
}

export default new ProjectBar();
